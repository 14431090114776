<template>
  <header class="mobile-header">
    <div class="menu-wrapper">
      <div class="menu">
        <el-icon :size="40" color="#ff5c41" @click="showDrawer"
          ><Expand
        /></el-icon>
      </div>
      <div>
        <the-logo></the-logo>
      </div>
    </div>

    <el-drawer
      v-model="drawer"
      title="Избери категория"
      :with-header="false"
      direction="ltr"
      size="320px"
      style="background-color: #f4f2ee; border-right: 1px solid #dfdeda"
      handleClose
      @click="hideDrawer"
    >
      <nav class="mobile-navigation">
        <div class="mobile-navigation__logo">
          <the-logo></the-logo>
        </div>
        <ul>
          <li>
            <router-link to="/"
              ><el-icon><CaretRight /></el-icon> Начало </router-link
            >
          </li>
          <!-- <li>
            <router-link to="/new-buildings"
              ><el-icon><CaretRight /></el-icon> Ново строителство </router-link
            >
          </li> -->
          <li>
            <router-link to="/for-sale"
              ><el-icon><CaretRight /></el-icon> Продажба </router-link
            >
          </li>
          <li>
            <router-link to="/for-rent"
              ><el-icon><CaretRight /></el-icon> Наем </router-link
            >
          </li>
          <li>
            <router-link to="/about-us"
              ><el-icon><CaretRight /></el-icon> За нас </router-link
            >
          </li>
        </ul>
      </nav>
    </el-drawer>
  </header>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    hideDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
